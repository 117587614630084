import React from "react";
import accessImg from "../image/access.png";

function AccessDenied() {
  const backPage = () => {
    window.history.back();
  };
  return (
    <div>
      <div className="text-center mt-5 pt-5">
        <div className="row">
          <div className="col-sm-6">
            <img className="img-fluid" src={accessImg} alt="" />
          </div>
          <div className="col-sm-6 d-flex align-items-center">
            <div>
              <p className="fw-bold h6 text-danger mb-4">
                Contact your master Admin or Back Privious Page
              </p>
              <button
                className="btn btn-warning fw-bold text-dark px-4"
                onClick={backPage}
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccessDenied;
