import Nav from "../ChildComponents/Nav";
import "../CSS/profile.css";
import ProfileCover from "../image/profile-background.png";
import Profileimg from "../image/profile.png";
import BusinessIcon from "@mui/icons-material/Business";
import PhoneIcon from "@mui/icons-material/Phone";
import HomeIcon from "@mui/icons-material/Home";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import FlagIcon from "@mui/icons-material/Flag";
import EditIcon from "@mui/icons-material/Edit";
import PasswordIcon from "@mui/icons-material/Password";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BadgeIcon from "@mui/icons-material/Badge";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import UserNotFound from "../image/userNotFound.png";
import { userIdCollector } from "../Api/emailCollector";
import { getUserinfo, updateUser, updatePassword } from "../Api/user";
import { updateSubUserPassword, updateSubUser } from "../Api/subUser";
import { updateBusinessinfo } from "../Api/businessInfo";
import Form from "react-bootstrap/Form";
import CancelIcon from "@mui/icons-material/Cancel";
import Button from "react-bootstrap/Button";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL;

const api = axios.create({
  baseURL: `${baseURL}`,
});

function Profile() {
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [accountType, setAccountType] = useState({});
  const [businessInfo, setBusinessInfo] = useState({});
  const [inputDisable, setInputDisable] = useState(true);

  const [buseinessinfoId, setBuseinessinfoId] = useState();
  const [buseinessId, setBuseinessId] = useState();
  const [buseinessName, setBuseinessName] = useState();
  const [contactNumber, setContactNumber] = useState();
  const [address, setAddress] = useState();
  const [email, setEmail] = useState();
  const [country, setCountry] = useState();
  const [password, setPassword] = useState();
  const [userName, setUserName] = useState();
  const [userRole, setUserRole] = useState();
  const [userEmail, setUserEmail] = useState();

  const [editAcess, setEditAcess] = useState(false);
  const [closebtn, setClosebtn] = useState(false);
  const [businsessInfoFiled, setBusinsessInfoFiled] = useState(false);
  const [businsessInfoIditbtn, setBusinsessInfoIditbtn] = useState(true);

  // password edit related
  const [passwordInputFild, setPasswordInputFild] = useState(false);
  const [passwordInputeditbtn, setPasswordInputeditbtn] = useState(true);

  // sub user edit related
  const [subUseerInputFild, setSubUseerInputFild] = useState(false);
  const [subUseerInputFildbtn, setSubUseerInputFildbtn] = useState(true);

  const { id } = useParams();
  const uRLuserId = parseFloat(id);

  const chackEditAcess = async () => {
    const getUserId = await userIdCollector();
    const isEditAccessAllowed = uRLuserId === getUserId;
    setEditAcess(isEditAccessAllowed);
  };
  async function getInternalData() {
    setInputDisable(false);
    const token = localStorage.getItem("token");
    // business Info
    const businessInforesponse = await api.get(`/businessInfo`, {
      headers: {
        Authorization: token,
      },
    });
    const businessdata = businessInforesponse.data;
    const finalbusinessdata = businessdata.newBusinessInfo;

    // subUser
    const subuserresponse = await api.get(`/subuser`, {
      headers: {
        Authorization: token,
      },
    });
    const subuserdata = subuserresponse.data;
    const finalSubuserdata = subuserdata.newSubUser;

    // Main User
    const mainresponse = await api.get(`/users`, {
      headers: {
        Authorization: token,
      },
    });
    const maindata = mainresponse.data;
    const finalUserData = maindata.user;
    const filterBigUser = finalUserData.find(
      (item) => item.userId === uRLuserId
    );

    if (filterBigUser === undefined) {
      setAccountType(false);
      // filter  user
      const filterSubUserObj = finalSubuserdata.find(
        (item) => item.userId === uRLuserId
      );
      setUserData(filterSubUserObj);
      setUserName(filterSubUserObj.userName);
      setUserRole(filterSubUserObj.userRole);
      setUserEmail(filterSubUserObj.userEmail);
      // filter main user
      const filtermainBigUser = finalUserData.find(
        (item) => item._id === filterSubUserObj.businessID._id
      );
      // filter business info
      const filterBuseinssAccount = finalbusinessdata.find(
        (item) => item.businessID._id === filtermainBigUser._id
      );
      setBusinessInfo(filterBuseinssAccount);
    } else {
      setUserData(filterBigUser);
      const filterBuseinssAccount = finalbusinessdata.find(
        (item) => item.businessID._id === filterBigUser._id
      );
      setBusinessInfo(filterBuseinssAccount);
      setBuseinessinfoId(filterBuseinssAccount._id);
      setBuseinessId(filterBuseinssAccount.businessID._id);
      setBuseinessName(filterBuseinssAccount.businessID.org);
      setContactNumber(filterBuseinssAccount.contactNumber);
      setAddress(filterBuseinssAccount.address);
      setEmail(filterBuseinssAccount.businessID.email);
      setCountry(filterBuseinssAccount.businessID.country);
    }
    setInputDisable(true);
  }

  const getUser = async () => {
    try {
      setLoading(true);
      await getInternalData();
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await chackEditAcess();
      await getUser();
    };
    fetchData();
  }, [uRLuserId]);

  useEffect(() => {
    chackEditAcess();
  }, [editAcess]);

  // buisnss info ediging
  const businessInfoHandalar = () => {
    setClosebtn(true);
    setBusinsessInfoIditbtn(false);
    setBusinsessInfoFiled(true);
  };
  // business info colose hander
  const closeBtnHandalar = () => {
    getInternalData();
    setEditAcess(true);
    setClosebtn(false);
    setBusinsessInfoIditbtn(true);
    setBusinsessInfoFiled(false);
  };
  // sub user edit handalar
  const userInfoHandalar = () => {
    // setSubUseerInputFild(true);
    // setSubUseerInputFildbtn(false);
    toast.warning(
      "Contact Your Business User ! You can't Change this Information"
    );
  };
  // sub user edit close handalar
  // const closeBtnforSubUser = () => {
  //   getInternalData();
  //   setSubUseerInputFild(false);
  //   setSubUseerInputFildbtn(true);
  // };
  // edit password hander
  const passwordHandalar = () => {
    setPasswordInputeditbtn(false);
    setPasswordInputFild(true);
    setPassword("");
  };
  // edit password hander close
  const closeBtnforPassword = () => {
    getInternalData();
    setPasswordInputeditbtn(true);
    setPasswordInputFild(false);
  };
  // business infomation update
  const businessInfoUpdateHandalar = async (e) => {
    e.preventDefault();
    const emailPattern = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    const numberPattern = /^[0-9]*$/;
    if (!buseinessName || !contactNumber || !address || !email) {
      return toast.error(`Somethink blank filed !!`);
    }
    if (!emailPattern.test(email)) {
      return toast.error(" Invalid Email");
    }
    if (!numberPattern.test(contactNumber)) {
      return toast.error(" Invalid Mobile Number");
    }
    try {
      await updateUser(buseinessId, buseinessName, email, country);
      await updateBusinessinfo(buseinessinfoId, contactNumber, address);
      getUser();
      toast(`Successfully Updated`);
      setClosebtn(false);
      setBusinsessInfoIditbtn(true);
      setBusinsessInfoFiled(false);
    } catch (error) {
      if (error.response && error.response.status === 304) {
        toast.error("Already using this email.");
      } else {
        toast.error(error.message);
      }
    }
  };
  // user and sub user password update
  const passwordUpdateHandalar = async (e) => {
    e.preventDefault();
    if (!password) {
      return toast.error(`Password Filed Empty !!`);
    }
    try {
      const updateId = businessInfo.businessID._id;
      const allMainUser = await getUserinfo();
      const userTypeforUpdatePass = allMainUser.find(
        (item) => item.userId === uRLuserId
      );
      const token = localStorage.getItem("token");
      if (userTypeforUpdatePass === undefined) {
        const subuserresponse = await api.get(`/subuser`, {
          headers: {
            Authorization: token,
          },
        });
        const subuserdata = subuserresponse.data.newSubUser;
        const subUserPass = await subuserdata.find(
          (item) => item.userId === uRLuserId
        );
        const subuserId = subUserPass._id;
        await updateSubUserPassword(subuserId, password);
        // toast(`Successfully Updated`);
        closeBtnforPassword();
      } else {
        await updatePassword(updateId, password);
        toast(`Successfully Updated`);
        closeBtnforPassword();
      }
    } catch (error) {
      toast.error(`Something went wrong while updating.`);
    }
  };

  return (
    <div>
      <Nav />
      {loading ? (
        <div className="mt-5 text-center">
          <div className="spinner-grow text-info mt-4 " role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="container my-5">
          {!userData ? (
            <div className="text-center mt-5">
              <img className="img-fluid" src={UserNotFound} alt="" />
            </div>
          ) : (
            <div>
              <div className="profile-cover">
                <img
                  className="cover-img shadow rounded-3"
                  src={ProfileCover}
                  alt=""
                />
                <img className="profile-img " src={Profileimg} alt="" />
              </div>
              <div className="mt-5 pt-2 ms-5 ps-4">
                <h3 className="fw-bold">
                  {userData.userName !== undefined
                    ? userData.userName
                    : userData.org}
                </h3>
                <div className="d-flex">
                  <div
                    className={
                      userData.userStatus === "active"
                        ? "bip"
                        : userData.userStatus === "inactive"
                        ? "redbip"
                        : "bip"
                    }
                  ></div>
                  <p className="mt-1">
                    {!userData.userStatus
                      ? "Active"
                      : userData.userStatus.charAt(0).toUpperCase() +
                        userData.userStatus.slice(1)}
                  </p>
                  <p className="account-type ms-3">
                    {accountType ? "Business Account" : "Sub User Account"}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <div className="bg-white mt-3 rounded shadow p-3  profile-details">
                    <p className=" fw-bold text-info-emphasis border-bottom border-2 mb-4">
                      Business Informataion
                    </p>
                    {/* Business Name */}
                    {businsessInfoFiled ? (
                      <div>
                        <Form.Label>Business Name</Form.Label>
                        <Form.Control
                          value={buseinessName}
                          onChange={(e) => setBuseinessName(e.target.value)}
                          size="sm"
                          className="shadow form-input mb-3"
                          type="text"
                          placeholder="Business Name"
                          disabled={!inputDisable}
                        />
                      </div>
                    ) : (
                      <p>
                        <BusinessIcon className="me-2" />
                        {userData.businessID?.org || userData.org}
                      </p>
                    )}
                    {/* business contact number */}
                    {businsessInfoFiled ? (
                      <div>
                        <Form.Label>Contact Number</Form.Label>
                        <Form.Control
                          value={contactNumber}
                          onChange={(e) => setContactNumber(e.target.value)}
                          size="sm"
                          className="shadow form-input mb-3"
                          type="text"
                          placeholder="Business Name"
                          disabled={!inputDisable}
                        />
                      </div>
                    ) : (
                      <p>
                        <PhoneIcon className="me-2" />
                        {businessInfo.contactNumber}
                      </p>
                    )}
                    {/* business Address */}
                    {businsessInfoFiled ? (
                      <div>
                        <Form.Label>Business Address</Form.Label>
                        <Form.Control
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          size="sm"
                          className="shadow form-input mb-3"
                          type="text"
                          placeholder="Business Name"
                          disabled={!inputDisable}
                        />
                      </div>
                    ) : (
                      <p>
                        <HomeIcon className="me-2" />
                        {businessInfo.address}
                      </p>
                    )}
                    {/* business Email */}
                    {businsessInfoFiled ? (
                      <div>
                        <Form.Label>Business Email</Form.Label>
                        <Form.Control
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          size="sm"
                          className="shadow form-input mb-3"
                          type="text"
                          placeholder="Business Name"
                          disabled={!inputDisable}
                        />
                      </div>
                    ) : (
                      <p>
                        <AlternateEmailIcon className="me-2" />
                        {userData.businessID?.email || userData.email}
                      </p>
                    )}
                    {/* Country */}
                    {businsessInfoFiled ? (
                      <div>
                        <Form.Label>Country</Form.Label>
                        <Form.Control
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                          size="sm"
                          className="shadow form-input mb-3"
                          type="text"
                          placeholder="Business Name"
                          disabled={!inputDisable}
                        />
                      </div>
                    ) : (
                      <p>
                        <FlagIcon className="me-2" />
                        {userData.businessID?.country || userData.country}
                      </p>
                    )}

                    {accountType && editAcess && businsessInfoIditbtn ? (
                      <EditIcon
                        onClick={businessInfoHandalar}
                        className="profile-details-edit pointer"
                      />
                    ) : (
                      ""
                    )}
                    {!closebtn ? (
                      ""
                    ) : (
                      <div>
                        <CancelIcon
                          onClick={closeBtnHandalar}
                          className="profile-details-close pointer"
                        />
                        <Button
                          className="btn btn-brand mb-3 me-3"
                          variant="primary"
                          type="submit"
                          size="sm"
                          onClick={businessInfoUpdateHandalar}
                        >
                          Save Change
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  {accountType ? (
                    ""
                  ) : (
                    <div className="bg-white mt-3 rounded shadow p-3  profile-details">
                      <p className=" fw-bold text-info-emphasis border-bottom border-2 mb-4">
                        User Informataion
                      </p>
                      {/* User Name */}
                      {subUseerInputFild ? (
                        <div>
                          <Form.Label>User Name</Form.Label>
                          <Form.Control
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                            size="sm"
                            className="shadow form-input mb-3"
                            type="text"
                            placeholder="Business Name"
                          />
                        </div>
                      ) : (
                        <p>
                          <AccountCircleIcon className="me-2" />
                          {userData.userName}
                        </p>
                      )}
                      {/* User Role */}
                      {subUseerInputFild ? (
                        <div>
                          <Form.Label>User Role</Form.Label>
                          <Form.Control
                            value={userRole}
                            onChange={(e) => setUserRole(e.target.value)}
                            size="sm"
                            className="shadow form-input mb-3"
                            type="text"
                            placeholder="Business Name"
                          />
                        </div>
                      ) : (
                        <p>
                          <BadgeIcon className="me-2" />
                          {userData.userRole}
                        </p>
                      )}
                      {/* User Email */}
                      {subUseerInputFild ? (
                        <div>
                          <Form.Label>User Email</Form.Label>
                          <Form.Control
                            value={userEmail}
                            onChange={(e) => setUserEmail(e.target.value)}
                            size="sm"
                            className="shadow form-input mb-3"
                            type="text"
                            placeholder="Business Name"
                          />
                        </div>
                      ) : (
                        <p>
                          <ContactMailIcon className="me-2" />
                          {userData.userEmail}
                        </p>
                      )}

                      {subUseerInputFildbtn && editAcess ? (
                        <EditIcon
                          onClick={userInfoHandalar}
                          className="profile-details-edit pointer"
                        />
                      ) : (
                        ""
                      )}
                      {/* {subUseerInputFild ? (
                        <div>
                          <CancelIcon
                            onClick={closeBtnforSubUser}
                            className="profile-details-close pointer"
                          />
                          <Button
                            className="btn btn-brand mb-3 me-3"
                            variant="primary"
                            type="submit"
                            size="sm"
                            onClick={subUserInfoUpdateHandalar}
                          >
                            Save Change
                          </Button>
                        </div>
                      ) : (
                        ""
                      )} */}
                    </div>
                  )}

                  <div className="bg-white mb-4 mt-3 rounded shadow p-3  profile-details">
                    {/* Country */}
                    {passwordInputFild ? (
                      <div>
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          size="sm"
                          className="shadow form-input mb-3"
                          type="password"
                          placeholder="Enter Your New Password !!"
                        />
                      </div>
                    ) : (
                      <p>
                        <span style={{ marginRight: "10px" }}>Password</span>
                        <PasswordIcon className="me-2" />
                        ****************
                      </p>
                    )}

                    {passwordInputeditbtn && editAcess ? (
                      <EditIcon
                        onClick={passwordHandalar}
                        className="profile-details-edit pointer"
                      />
                    ) : (
                      ""
                    )}
                    {passwordInputFild ? (
                      <div>
                        <CancelIcon
                          onClick={closeBtnforPassword}
                          className="profile-details-close pointer"
                        />
                        <Button
                          className="btn btn-brand mb-3 me-3"
                          variant="primary"
                          type="submit"
                          size="sm"
                          onClick={passwordUpdateHandalar}
                        >
                          Save Change
                        </Button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="bg-white mt-3 rounded shadow p-3  profile-details">
                  <p className=" fw-bold text-success">Access Log History</p>
                  <div>
                    <div className="d-flex">
                      <div className="bip"></div>
                      <p className="mt-0 mb-2">
                        Md. Afser Uddin Create a Business Type in Muktodhara
                        Business Account <br />
                        <span className="mt-1" style={{ color: "#3a3a3a69" }}>
                          12th, January, 2024 at 09.50.50PM
                        </span>
                      </p>
                    </div>
                    <div className="d-flex">
                      <div className="bip"></div>
                      <p className="mt-0 mb-2">
                        Md. Afser Uddin Create a Client In Muktodhara Technology
                        Ltd <br />
                        <span className="mt-1" style={{ color: "#3a3a3a69" }}>
                          12th, January, 2024 at 09.50.50PM
                        </span>
                      </p>
                    </div>
                    <div className="d-flex">
                      <div className="bip"></div>
                      <p className="mt-0 mb-2">
                        Md. Afser Uddin Delet an Invoice #IN098 form Muktodhara
                        Technology Ltd <br />
                        <span className="mt-1" style={{ color: "#3a3a3a69" }}>
                          12th, January, 2024 at 09.50.50PM
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <ToastContainer />
    </div>
  );
}

export default Profile;
