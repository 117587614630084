import React from "react";
import "../CSS/footer.css";

function FooterText() {
  return (
    <div className="bg-dark height text-white mb-0 fixed-bottom  d-flex justify-content-center">
      <p className="footer-text pt-1">
        &copy; 2023 Trilliowner Inc. All Rights Reserved.
      </p>
    </div>
  );
}

export default FooterText;
