import React from "react";
import landingImage from "../image/landing-page-logo.png";

// import css
import "../CSS/hero.css";

function HeroTitle() {
  return (
    <div>
      <div>
        <img className="img-fluid " src={landingImage} alt="Coaching Center" />
        <h1 className="fw-bold h4 mb-0 display-2 text-primary">trilliowner </h1>
        <p className="mb-0"> the Best Way speed for You !!</p>
      </div>
    </div>
  );
}

export default HeroTitle;
