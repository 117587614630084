import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const baseURL = process.env.REACT_APP_BASE_URL;

const api = axios.create({
  baseURL: `${baseURL}`,
});

export const getSubUserinfo = async () => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.get(`/subuser`, {
      headers: {
        Authorization: token,
      },
    });
    const data = response.data;
    const rowData = data.newSubUser;
    return rowData;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
export const updateSubUser = async (
  id,
  userId,
  userName,
  userRole,
  userStatus,
  createBy,
  updateBy,
  businessID
) => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.patch(
      `/subuser/${id}`,
      {
        userId,
        userName,
        userRole,
        userStatus,
        createBy,
        updateBy,
        businessID,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    if (response.status === 200) {
      toast.success("Successfully Updated");
    } else if (response.status === 201) {
      toast.error("This Email Already use !!");
    } else {
      toast.error(`Error: ${response.status} - ${response.statusText}`);
    }
    return response.data;
  } catch (error) {
    toast.error("An error occurred while updating a user.");
    throw error;
  }
};
export const updateSwitch = async (id, userStatus) => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.patch(
      `/subuser/${id}`,
      {
        userStatus,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    if (response.status === 200) {
      toast.success("Successfully Updated");
    } else if (response.status === 201) {
      toast.error("This Email Already use !!");
    } else {
      toast.error(`Error: ${response.status} - ${response.statusText}`);
    }
    return response.data;
  } catch (error) {
    toast.error("An error occurred while updating a user.");
    throw error;
  }
};

export const updateSubUserEmail = async (id, userEmail) => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.patch(
      `/subuser/${id}`,
      {
        id,
        userEmail,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    if (response.status === 200) {
      toast.success("Successfully Updated");
    } else if (response.status === 201) {
      toast.error("This Email Already use !!");
    } else {
      toast.error(`Error: ${response.status} - ${response.statusText}`);
    }
    return response.data;
  } catch (error) {
    console.error("Error updating subuser:", error);
    toast.error("An error occurred while updating a user.");
    throw error;
  }
};

export const createSubUser = async (
  userName,
  userRole,
  userEmail,
  userPass,
  userStatus,
  createBy,
  updateBy,
  businessID
) => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.post(
      "/subuser",
      {
        userName,
        userRole,
        userEmail,
        userPass,
        userStatus,
        createBy,
        updateBy,
        businessID,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (response.status === 201) {
      toast.error("Alredy use this email.com");
    } else if (response.status === 200) {
      toast.success("Sucessfully Add");
      return response.data;
    } else if (response.status === 401) {
      toast.error("Unauthorized access. Please log in again.");
    } else {
      toast.error(`Error: ${response.status} - ${response.statusText}`);
    }
    // return response.data;
  } catch (error) {
    console.error("Error creating subuser:", error);
    toast.error("An error occurred while creating a user.");
    throw error;
  }
};

export const updateSubUserPassword = async (id, userPass) => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.patch(
      `/subuser/password/${id}`,
      {
        userPass,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    if (response.status === 200) {
      toast.success("Successfully Updated");
    } else if (response.status === 201) {
      toast.error("This Email Already use !!");
    } else {
      toast.error(`Error: ${response.status} - ${response.statusText}`);
    }
    return response.data;
  } catch (error) {
    console.error("Error updating subuser:", error);
    toast.error("An error occurred while updating a user.");
    throw error;
  }
};
