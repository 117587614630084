import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL;

const api = axios.create({
  baseURL: `${baseURL}`,
});

export const emailCollector = async () => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.get("/routeprotection", {
      headers: {
        Authorization: token,
      },
    });
    const data = response.data.email;
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error; // Make sure to propagate the error to the calling component
  }
};

export const leadData = async () => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${baseURL}/lead`, {
      headers: {
        Authorization: token,
      },
    });
    const data = response.data;
    const finalData = data.newLeadInfo;

    return finalData;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const clientData = async () => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${baseURL}/lead/client`, {
      headers: {
        Authorization: token,
      },
    });
    const data = response.data;
    const finalData = data.newLeadInfo;

    return finalData;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const singleClient = async (id) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${baseURL}/lead/${id}`, {
      headers: {
        Authorization: token,
      },
    });
    const data = response.data;
    const newLeadInfos = data.newLeadInfo;

    return newLeadInfos;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const createBusinessLead = async (
  businessType,
  dataSource,
  contactPersonName,
  contactPersonMobileNumber,
  contactPersonEmail,
  businessName,
  businessMobile,
  businessEmail,
  businessAddress,
  businessCountry,
  businessDistrict,
  remark,
  clientType,
  openingBalance
) => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.post(
      "/lead",
      {
        businessType,
        dataSource,
        contactPersonName,
        contactPersonMobileNumber,
        contactPersonEmail,
        businessName,
        businessMobile,
        businessEmail,
        businessAddress,
        businessCountry,
        businessDistrict,
        remark,
        clientType,
        openingBalance,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response.data; // Return the data if needed
  } catch (error) {
    throw error; // Re-throw the error for the calling component to handle
  }
};

export const updateLeadData = async (
  id,
  businessType,
  dataSource,
  contactPersonName,
  contactPersonMobileNumber,
  contactPersonEmail,
  businessName,
  businessMobile,
  businessEmail,
  businessAddress,
  businessCountry,
  businessDistrict,
  remark,
  clientType,
  openingBalance
) => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.patch(
      `/lead/${id}`,
      {
        businessType,
        dataSource,
        contactPersonName,
        contactPersonMobileNumber,
        contactPersonEmail,
        businessName,
        businessMobile,
        businessEmail,
        businessAddress,
        businessCountry,
        businessDistrict,
        remark,
        clientType,
        openingBalance,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error; // You might want to handle errors in the calling component
  }
};
export const updateClientType = async (id, clientType) => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.patch(
      `/lead/${id}`,
      {
        clientType,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error; // You might want to handle errors in the calling component
  }
};
