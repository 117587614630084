import Nav from "../ChildComponents/Nav";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Switch from "@mui/material/Switch";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { userIdCollector } from "../Api/emailCollector";
import { getUserinfo } from "../Api/user";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

import {
  getSubUserinfo,
  updateSubUser,
  createSubUser,
  updateSubUserEmail,
  updateSwitch,
} from "../Api/subUser";
import "../CSS/usermanagement.css";
import AccessDenied from "./AccessDenied";
import EditNoteIcon from "@mui/icons-material/EditNote";

function UserManagement() {
  const [sectionShow, setSectionShow] = useState(true);
  const [allUserInfo, setAllUserInfo] = useState([]);
  const [currectUser, setCurrectUser] = useState();
  const [loading, setLoading] = useState(false);
  const [toBeEditedSubUserObj, setToBeEditedSubUserObj] = useState({});
  const [emailDiabled, setEmailDiabled] = useState(false);
  const [allFildDiabled, setAllFildDiabled] = useState(false);
  const [buttonDiabled, setButtonDiabled] = useState(false);
  const [emailButton, setEmailButton] = useState(false);
  const [submitBtnHide, setSubmitBtnHide] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const [editableobj, setEditableobj] = useState();

  const [dataTable, setDataTable] = useState([]);
  const [switchMode, setSwitchMode] = useState();

  const [userName, setUserName] = useState();
  const [userRole, setUserRole] = useState();
  const [userEmail, setUserEmail] = useState();
  const [userPassword, setUserPassword] = useState();
  const [userStatus, setUserStatus] = useState("active");
  const [createBy, setCreateBy] = useState();
  const [updateBy, setUpdateBy] = useState("");

  const getUser = async () => {
    const finaluserId = await userIdCollector();
    setCurrectUser(finaluserId);
  };

  // const changeStaus = (value) => {
  //   setUserStatus(value);
  // };

  const getData = async () => {
    try {
      setLoading(true);
      setButtonDiabled(true);
      const result = await getUserinfo();
      const subUserData = await getSubUserinfo();
      const finaluserId = await userIdCollector();
      const filterSubUsers = subUserData.filter(
        (item) => parseInt(item.createBy) === parseInt(finaluserId)
      );
      setAllUserInfo(result);
      setDataTable(filterSubUsers);
    } catch (error) {
    } finally {
      setLoading(false);
      setButtonDiabled(false);
    }
  };

  const sectionCondition = async () => {
    setLoading(true);
    const result = await getUserinfo();
    const finaluserId = await userIdCollector();
    const filter = result.find((item) => item.userId === finaluserId);
    if (!filter) {
      setSectionShow(false);
    }
  };

  useEffect(() => {
    getData();
    getUser();
    sectionCondition();
  }, []);

  const createHandelar = async (e) => {
    e.preventDefault();

    const emailPattern = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    const businessIdfilterable = allUserInfo.find(
      (item) => item.userId === currectUser
    );

    try {
      if (!userName || !userRole || !userEmail || !userPassword) {
        toast.error("Somethink input is empty");
      } else if (!emailPattern.test(userEmail)) {
        toast.error(" Wrong Email");
      } else {
        setLoading(true);
        setButtonDiabled(true);
        await createSubUser(
          userName,
          userRole,
          userEmail,
          userPassword,
          userStatus,
          currectUser,
          updateBy,
          businessIdfilterable._id
        );
        setUserName("");
        setUserRole("");
        setUserEmail("");
        setUserPassword("");
        getData();
        setUserStatus();
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
      setLoading(false);
      setButtonDiabled(false);
    }
  };

  const emailEditHandler = (_Id) => {
    setSubmitBtnHide(true);
    setEditMode(true);
    setAllFildDiabled(true);
    setEmailButton(true);
    setEmailDiabled(false);
    const toBeEditedSubUser = dataTable.find((item) => item._id === _Id);
    setToBeEditedSubUserObj(toBeEditedSubUser);
    if (toBeEditedSubUser) {
      setEditableobj(toBeEditedSubUser);
      setUserName(toBeEditedSubUser.userName);
      setUserRole(toBeEditedSubUser.userRole);
      setUserEmail(toBeEditedSubUser.userEmail);
      setUserStatus(toBeEditedSubUser.userStatus);
      setUserPassword("");
    } else {
      console.error(`Developer Type with ID ${_Id} not found.`);
    }
  };

  const editHandler = (_Id) => {
    setSubmitBtnHide(false);
    setAllFildDiabled(false);
    setEditMode(true);
    setEmailDiabled(true);
    const toBeEditedSubUser = dataTable.find((item) => item._id === _Id);
    setToBeEditedSubUserObj(toBeEditedSubUser);
    console.log(toBeEditedSubUser);
    if (toBeEditedSubUser) {
      setEditableobj(toBeEditedSubUser);
      setUserName(toBeEditedSubUser.userName);
      setUserRole(toBeEditedSubUser.userRole);
      setUserEmail(toBeEditedSubUser.userEmail);
      setUserPassword("");
    } else {
      console.error(`Developer Type with ID ${_Id} not found.`);
    }
  };

  const updateSwitchHandalr = async (userId) => {
    try {
      const toBeUpdatedSubUser = dataTable.find((item) => item._id === userId);
      if (toBeUpdatedSubUser.userStatus === "active") {
        // setUserStatus("inactive");
        const updateStatus = "inactive";
        await updateSwitch(toBeUpdatedSubUser._id, updateStatus);
        getData();
      } else {
        // setUserStatus("active");
        const updateStatus = "active";
        await updateSwitch(toBeUpdatedSubUser._id, updateStatus);
        getData();
      }
    } catch (error) {
      toast.error(`Something went wrong while updating.`);
    }
  };

  const updateHandaler = async (e) => {
    e.preventDefault();
    const emailPattern = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    if (!userName || !userRole || !userEmail) {
      toast.error(`Any Input fild empty`);
      return;
    } else if (!emailPattern.test(userEmail)) {
      toast.error(" Wrong Email");
    } else {
      try {
        await updateSubUser(
          toBeEditedSubUserObj._id,
          toBeEditedSubUserObj.userId,
          userName,
          userRole,
          userStatus,
          createBy,
          currectUser,
          toBeEditedSubUserObj.businessID_id
        );
        setUserName("");
        setUserRole("");
        setUserEmail("");
        setUserPassword("");
        getData();
        setEditMode(false);
        setEmailDiabled(false);
      } catch (error) {
        toast.error(`Something went wrong while updating.`);
      }
    }
  };

  const updateEmailHandaler = async (e) => {
    e.preventDefault();
    const emailPattern = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    if (!userName || !userRole || !userEmail) {
      toast.error(`Any Input fild empty`);
      return;
    } else if (!emailPattern.test(userEmail)) {
      toast.error(" Wrong Email");
    } else {
      try {
        await updateSubUserEmail(toBeEditedSubUserObj._id, userEmail);
        setUserName("");
        setUserRole("");
        setUserEmail("");
        setUserPassword("");
        getData();
        setEditMode(false);
        setEmailDiabled(false);
        setEmailButton(false);
        setSubmitBtnHide(false);
        setAllFildDiabled(false);
      } catch (error) {
        toast.error(`Something went wrong while updating.`);
      }
    }
  };

  const switchStatus = (userStatus) => {
    return userStatus === "active";
  };

  return (
    <div>
      <Nav />
      {sectionShow ? (
        <div>
          <div className="my-3 container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-4 mb-3">
                <div className="shadow p-2 rounded">
                  <Form onSubmit={editMode ? updateHandaler : createHandelar}>
                    <Form.Group
                      className="mb-3 mt-2"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>User Name</Form.Label>
                      <Form.Control
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                        size="sm"
                        className="shadow form-input"
                        type="text"
                        placeholder="Business Name"
                        disabled={allFildDiabled}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3 mt-2"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>User Role</Form.Label>

                      <Form.Select
                        size="sm"
                        aria-label="Default select example"
                        value={userRole}
                        onChange={(e) => setUserRole(e.target.value)}
                        disabled={allFildDiabled}
                      >
                        <option>Select User Role</option>
                        <option> Admin</option>
                        <option> Sub-Admin</option>
                        <option> Business-Developer</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group
                      className="mb-3 mt-2"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>User Email</Form.Label>
                      <Form.Control
                        value={userEmail}
                        onChange={(e) => setUserEmail(e.target.value)}
                        size="sm"
                        className="shadow form-input"
                        type="text"
                        placeholder="Enter User Email"
                        disabled={emailDiabled}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3 mt-2"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>User Password</Form.Label>
                      <Form.Control
                        value={userPassword}
                        onChange={(e) => setUserPassword(e.target.value)}
                        size="sm"
                        className="shadow form-input"
                        type="password"
                        placeholder="Enter Your Password !!"
                        disabled={allFildDiabled || emailDiabled}
                      />
                    </Form.Group>
                    <div>
                      {submitBtnHide ? (
                        ""
                      ) : (
                        <Button
                          className="btn btn-brand mb-3 me-3"
                          variant="primary"
                          type="submit"
                          size="sm"
                          disabled={buttonDiabled}
                        >
                          {editMode ? "Save Change" : "Save Data"}
                        </Button>
                      )}

                      {editMode ? (
                        <div>
                          {!submitBtnHide ? (
                            ""
                          ) : (
                            <Button
                              className="btn btn-brand me-3"
                              variant="primary"
                              type="submit"
                              size="sm"
                              onClick={updateEmailHandaler}
                            >
                              Update Email
                            </Button>
                          )}

                          <Button
                            className="btn btn-danger "
                            variant="primary"
                            type="submit"
                            size="sm"
                            onClick={() => window.location.reload()}
                          >
                            Clear Data
                          </Button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </Form>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-8 ">
                <div className="shadow p-2 rounded">
                  <Table striped bordered hover size="sm">
                    <thead>
                      <tr>
                        <th>SL#</th>
                        <th>User Name</th>
                        <th>User Role</th>
                        <th>User Email</th>
                        <th>User ID</th>
                        <th>Create By</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr
                          className="spinner-grow text-info mt-4 "
                          role="status"
                        >
                          <td className="visually-hidden">Loading...</td>
                        </tr>
                      ) : (
                        // Data loaded state
                        dataTable
                          .slice()
                          .reverse()
                          .map((item, index, array) => (
                            <tr key={item._id}>
                              <td>{array.length - index}</td>
                              <td>{item.userName}</td>
                              <td>{item.userRole}</td>
                              <td>{item.userEmail}</td>
                              <td>
                                <Link
                                  to={`/profile/${item.userId}`}
                                  className="text-decoration-none text-dark"
                                >
                                  User ID - #{item.userId}
                                </Link>
                              </td>
                              <td>
                                <Link
                                  to={`/profile/${item.createBy}`}
                                  className="text-decoration-none text-dark"
                                >
                                  User ID - #{item.createBy}
                                </Link>
                              </td>
                              <td>
                                <Switch
                                  checked={switchStatus(item.userStatus)}
                                  onClick={() => updateSwitchHandalr(item._id)}
                                />
                              </td>
                              <td className="text-center ">
                                <Tooltip
                                  title="Edit Your information without Email"
                                  placement="top"
                                >
                                  <EditIcon
                                    onClick={() => editHandler(item._id)}
                                    className="pointer text-light bg-dark p-1 rounded mt-2"
                                  />
                                </Tooltip>
                                <Tooltip
                                  title="Edit Your Email"
                                  placement="top"
                                >
                                  <EditNoteIcon
                                    onClick={() => emailEditHandler(item._id)}
                                    className="pointer text-light bg-dark ms-2 p-1 rounded mt-2"
                                  />
                                </Tooltip>
                              </td>
                            </tr>
                          ))
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default UserManagement;
