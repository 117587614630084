import React, { useState, useEffect } from "react";
import Nav from "../ChildComponents/Nav";
import "../CSS/button.css";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import Table from "react-bootstrap/Table";
import FormatListNumberedRtlIcon from "@mui/icons-material/FormatListNumberedRtl";
import Button from "react-bootstrap/Button";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import EditIcon from "@mui/icons-material/Edit";
import AccessDenied from "./AccessDenied";
import { getUserRole } from "../Api/userRole";

import {
  dataSourceData,
  emailCollector,
  createDataSourceType,
  updateDataSource,
} from "../Api/dataSourcefatch";

function DataSource() {
  const userAccessList = ["Admin", "Sub-Admin"];

  const [access, setAccess] = useState(false);

  const [dataTable, setDataTable] = useState([]);
  const [userEmail, setUserEmail] = useState("");

  const [entryForm, setEntryForm] = useState(false);
  const [leadTable, setLeadTable] = useState(true);

  const [editMode, setEditMode] = useState(false);
  const [editableobj, setEditableobj] = useState();

  const [dataSourceName, setDataSourceName] = useState("");
  const [particular, setParticular] = useState("");

  const [filteredData, setFilteredData] = useState([]);

  const fetchData = async () => {
    try {
      const result = await dataSourceData();
      const email = await emailCollector();
      setDataTable(result);
      setUserEmail(email);
      setFilteredData(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchDataAndSetCurrentUser = async () => {
      const userRole = await getUserRole();
      console.log(userRole);
      if (userAccessList.includes(userRole)) {
        fetchData();
        setAccess(false);
      } else {
        setAccess(true);
      }
    };

    fetchDataAndSetCurrentUser();
  }, []);

  const createHandelar = async (e) => {
    e.preventDefault();

    try {
      if (!dataSourceName) {
        toast.error("Business Type is Empty");
      } else {
        await createDataSourceType(dataSourceName, particular);

        toast("Successfully Added");
        openTable();
        setDataSourceName("");
        setParticular("");
        fetchData();
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    }
  };

  const editHandler = (_Id) => {
    setEditMode(true);
    const toBeEditedDataSource = dataTable.find((item) => item._id === _Id);

    if (toBeEditedDataSource) {
      setEditableobj(toBeEditedDataSource);
      openEntry();
      setDataSourceName(toBeEditedDataSource.dataSourceName);
      setParticular(toBeEditedDataSource.particular);
    } else {
      console.error(`Developer Type with ID ${_Id} not found.`);
    }
  };

  const updateHandaler = async (e) => {
    e.preventDefault();
    if (!dataSourceName) {
      toast.error(`Please provide Business Type Name`);
      return;
    }

    try {
      await updateDataSource(editableobj._id, dataSourceName, particular);
      setDataSourceName("");
      setParticular("");
      toast(`Successfully Updated`);
      setEditMode(false);
      fetchData();
      openTable();
    } catch (error) {
      toast.error(`Something went wrong while updating.`);
    }
  };

  const openEntry = () => {
    setLeadTable(false);
    setEntryForm(true);
  };
  const openTable = () => {
    setEntryForm(false);
    setLeadTable(true);
  };

  const filterdata = async (value) => {
    const filteredView = dataTable.filter((item) => {
      return (
        item.dataSourceName.toLowerCase().includes(value.toLowerCase()) ||
        item.particular.toLowerCase().includes(value.toLowerCase()) ||
        item.createBy.toLowerCase().includes(value.toLowerCase())
      );
    });

    setFilteredData(filteredView);
  };

  return (
    <div>
      {/* header */}
      <Nav />
      {/* icon with button */}
      {!access ? (
        <>
          {" "}
          <div className="container my-3">
            <div>
              <div className=" inner-nav-icon my-4 ">
                <Link
                  onClick={openTable}
                  className={`p-3 me-3 bg-light shadow rounded ${
                    leadTable
                      ? "border border-1 border-solid border-primary"
                      : ""
                  }`}
                >
                  <FormatListNumberedRtlIcon className="text-center" />
                </Link>
                <Link
                  onClick={openEntry}
                  className={`p-3 me-3 bg-light shadow rounded ${
                    entryForm
                      ? "border border-1 border-solid border-primary"
                      : ""
                  }`}
                >
                  <LibraryAddIcon className="text-center" />
                </Link>
              </div>
            </div>
          </div>
          {/* Page Title */}
          <div className="container mt-5">
            <p className="fw-bold h5 title-color">Data Source Type</p>
          </div>
          {/* entry form in body */}
          {entryForm ? (
            <div className="container mt-3">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                  <div className="shadow p-2 rounded">
                    <Form onSubmit={editMode ? updateHandaler : createHandelar}>
                      <Form.Group
                        className="mb-3 mt-2"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Data Source Type Name</Form.Label>
                        <Form.Control
                          value={dataSourceName}
                          onChange={(e) => setDataSourceName(e.target.value)}
                          size="sm"
                          className="shadow form-input"
                          type="text"
                          placeholder="Business Name"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3 mt-2"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Particular</Form.Label>

                        <Form.Control
                          value={particular}
                          onChange={(e) => setParticular(e.target.value)}
                          as="textarea"
                          aria-label="With textarea"
                          size="sm"
                          className="shadow form-input"
                          placeholder="Particular"
                        />
                      </Form.Group>
                      <div className="text-end me-3 my-4 mb-5">
                        <Button
                          className="btn btn-brand text-end"
                          variant="primary"
                          type="submit"
                          size="sm"
                        >
                          {editMode ? "Save Change" : "Save Data"}
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {/*Table in body */}
          {leadTable ? (
            <div className="mt-4 container">
              <div className="shadow p-2 rounded">
                <InputGroup size="sm" className="my-3 shadow-sm  rounded w-50 ">
                  <InputGroup.Text id="basic-addon1">Search</InputGroup.Text>
                  <Form.Control
                    placeholder="Search by Data Source Name, Particular or email"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    size="sm"
                    onChange={(e) => filterdata(e.target.value)}
                  />
                </InputGroup>
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr className="text-center">
                      <th>SL#</th>
                      <th>Data Source Name</th>
                      <th>Particular</th>
                      <th>Create By</th>
                      <th>Update By</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData && filteredData.length > 0 ? (
                      filteredData
                        .slice()
                        .reverse()
                        .map((item, index, array) => (
                          <tr key={item.id}>
                            <td>{array.length - index}</td>
                            <td>{item.dataSourceName}</td>
                            <td>{item.particular}</td>
                            <td>
                              <Link
                                to={`/profile/${item.createBy}`}
                                className="text-decoration-none text-dark"
                              >
                                User ID - # {item.createBy}
                              </Link>
                            </td>
                            <td>
                              {item.updateBy ? (
                                <Link
                                  to={`/profile/${item.updateBy}`}
                                  className="text-decoration-none text-dark"
                                >
                                  User ID - # {item.updateBy}
                                </Link>
                              ) : (
                                "Not Updated !!"
                              )}
                            </td>
                            <td className="text-center">
                              <EditIcon
                                onClick={() => editHandler(item._id)}
                                className="pointer text-light bg-dark p-1 rounded"
                              />
                            </td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td colSpan="6" className="text-center py-5">
                          No Data Available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          ) : (
            ""
          )}
          <ToastContainer />
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default DataSource;
