import React from "react";
import Nav from "../ChildComponents/Nav";
import landingImage from "../image/landing-page-logo.png";
import lead from "../image/deshborad/leads.png";
import review from "../image/deshborad/review.png";
import service from "../image/deshborad/service.png";
import "../CSS/deshbord.css";
import { Link } from "react-router-dom";

function Deshboard() {
  return (
    <div>
      <Nav />
      <div className="container mt-4">
        <div className="row">
          <div className="col-5">
            <div>
              <img
                className="img-fluid "
                src={landingImage}
                alt="Coaching Center"
              />
              <h1 className="fw-bold h4 mb-0 display-2 text-primary">
                trilliowner{" "}
              </h1>
              <p className="mb-0"> it's make little change !!</p>
            </div>
          </div>
          <div className="col-7 mt-5 d-flex align-items-center">
            <Link
              to="/marketing-lead"
              className="text-decoration-none text-white mx-4"
            >
              <div className="deshborad-btn shadow ">
                <img src={lead} lt="" />
                <p>Lead</p>
              </div>
            </Link>
            <Link
              to="/client-list"
              className="text-decoration-none text-white mx-4"
            >
              <div className="deshborad-btn shadow ">
                <img src={service} lt="" />
                <p>Client</p>
              </div>
            </Link>
            {/* <Link
              to="/marketing-lead"
              className="text-decoration-none text-white mx-4"
              aria-disabled
            >
              <div className="deshborad-btn shadow ">
                <img src={review} lt="" />
                <p>Commnent</p>
              </div>
            </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Deshboard;
