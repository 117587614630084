import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL;

const api = axios.create({
  baseURL: `${baseURL}`,
});

export const getBusinessinfo = async () => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${baseURL}/businessInfo`, {
      headers: {
        Authorization: token,
      },
    });
    const data = response.data;
    return data.newBusinessInfo;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const updateBusinessinfo = async (id, contactNumber, address) => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.patch(
      `/businessInfo/${id}`,
      {
        contactNumber,
        address,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};
