import axios from "axios";
import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";

// import css
import "../CSS/hero.css";

function HeroForm() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [org, setOrg] = useState("");
  const [country, setCountry] = useState("Bangladesh");
  const [password, setPassword] = useState("");
  const [massege, setMassege] = useState("");
  const [isEmailDisabled, setIsEmailDisabled] = useState(false);
  const [showNewReg, setShowNewReg] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const navigate = useNavigate();

  const baseURL = process.env.REACT_APP_BASE_URL;

  const emailChakHandalar = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const emailPattern = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
      if (!email) {
        setMassege("!! Please Enter Your Email Address");
      } else if (!emailPattern.test(email)) {
        setMassege("*** Invalid  Email");
      } else {
        const response = await axios.post(`${baseURL}/users/email`, {
          email,
        });
        if (response.status === 200) {
          setMassege("*** This is new mail");
          setIsEmailDisabled(true);
          setShowNewReg(true);
        }
        if (response.status === 201) {
          setMassege("*** Alredy use this email.com");
          setIsEmailDisabled(true);
          setShowLogin(true);
        }
      }
    } catch (error) {
      setMassege("*** Server error From Frontend");
    } finally {
      setLoading(false);
    }
  };

  const registationHandalar = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const emailPattern = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
      if (!email || !org || !country || !password) {
        setMassege("*** Some input fields are empty");
        return;
      }

      if (!email) {
        setMassege(" *** Email is empty");
      } else if (!emailPattern.test(email)) {
        setMassege("*** Wrong Email");
      } else {
        const response = await axios.post(`${baseURL}/users/signup`, {
          email,
          org,
          country,
          password,
        });
        if (response.status === 200) {
          const token = response.data.token;

          setMassege("*** Succefully Registation Done !!");

          localStorage.setItem("token", token);
          navigate("/admin");
        }
      }
    } catch (error) {
      setMassege(" *** Server error");
    } finally {
      setLoading(false);
    }
  };

  const loginistationHandalar = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      if (!email || !password) {
        setMassege("*** Some input fields are empty");
        return;
      }

      const emailPattern = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
      if (!email) {
        setMassege("*** Email is empty");
      } else if (!emailPattern.test(email)) {
        setMassege("*** Wrong Email");
      } else {
        const response = await axios.post(
          `${baseURL}/users/signin`,
          {
            email,
            password,
          },
          {
            headers: {
              "Access-Control-Allow-Origin": "*", // Set the appropriate origin or use '*' for any origin
              "Access-Control-Allow-Methods":
                "GET, POST, OPTIONS, PUT, PATCH, DELETE",
              "Access-Control-Allow-Headers":
                "Origin, Content-Type, X-Auth-Token",
              "Content-Type": "application/json",
              // Add any other headers as needed
            },
          }
        );
        if (response.status === 200) {
          const token = response.data.token;

          localStorage.setItem("token", token);

          setMassege(response.massege);
          navigate("/admin");
        }
        if (response.status === 401) {
          setMassege(" ***This Password is Wrong");
        }
        if (response.status === 402) {
          setMassege(" ***Your Accounts havebeen Suspandad !!");
        }
      }
    } catch (error) {
      // setMassege("*** This Password is Wrong");
      setMassege(error.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  function getMessage(showNewReg, showLogin) {
    if (showNewReg) {
      return "*** You don't Have Any Account !! Please Registration";
    } else if (showLogin) {
      return "*** Thanks For Staying with Us! Please Login";
    } else {
      return "Join Now";
    }
  }

  const message = getMessage(showNewReg, showLogin);

  const clearHandaler = () => {
    window.location.reload();
  };

  return (
    <div className="">
      <h5 className="fw-bold border-bottom border-white pb-3">{message}</h5>
      {loading ? (
        <div class="spinner-grow text-info mt-4 " role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      ) : (
        <Form>
          <Form.Label>Email address</Form.Label>
          <Form.Control
            className="mb-3 shadow"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="Enter your email..."
            disabled={isEmailDisabled}
          />
          {showNewReg && (
            <>
              <Form.Label>Organization Name</Form.Label>
              <Form.Control
                className="mb-3 shadow"
                value={org}
                onChange={(e) => setOrg(e.target.value)}
                type="text"
                placeholder="Enter your Organization Name"
              />
              <Form.Label>Country Name</Form.Label>
              <Form.Control
                className="mb-3 shadow bg-light"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                type="text"
                placeholder="Enter your Country Name"
                disabled
              />
              <Form.Label>Password</Form.Label>
              <Form.Control
                className="mb-3 shadow"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                placeholder="Enter your Password"
              />
            </>
          )}
          {showLogin && (
            <>
              <Form.Label>Password</Form.Label>
              <Form.Control
                className="mb-3 shadow"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                placeholder="Enter your Password"
              />
            </>
          )}
          <p className="text-danger rounded m-3 px-2 py-1 fw-bold">{massege}</p>
          <div className="d-flex justify-content-end">
            <button
              onClick={
                showNewReg
                  ? registationHandalar
                  : showLogin
                  ? loginistationHandalar
                  : emailChakHandalar
              }
              type="submit"
              className="btn btn-brand mt-3 "
            >
              {showNewReg ? "Registration" : showLogin ? "Login" : "Submit"}
            </button>
            {showLogin && (
              <>
                <button
                  onClick={clearHandaler}
                  type="submit"
                  className="btn btn-danger mt-3  ms-2"
                >
                  Clear
                </button>
              </>
            )}

            {showNewReg && (
              <>
                <button
                  onClick={clearHandaler}
                  type="submit"
                  className="btn btn-danger mt-3  ms-2"
                >
                  Clear
                </button>
              </>
            )}
          </div>
        </Form>
      )}
    </div>
  );
}

export default HeroForm;
