import axios from "axios";
import { getUserinfo } from "../Api/user";
import { getSubUserinfo } from "../Api/subUser";

const baseURL = process.env.REACT_APP_BASE_URL;

const api = axios.create({
  baseURL: `${baseURL}`,
});

export const emailCollector = async () => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${baseURL}/routeprotection`, {
      headers: {
        Authorization: token,
      },
    });
    const data = response.data.email;

    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const businessTypeData = async () => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${baseURL}/businesstype`, {
      headers: {
        Authorization: token,
      },
    });
    const data = response.data;
    const finalData = data.newBusinessInfo;
    return finalData;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const createBusinessType = async (
  businessTypeName,
  particular,
  userEmail
) => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.post(
      "/businesstype",
      {
        businessTypeName,
        particular,
        createBy: userEmail,
        updateBy: "",
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response.data; // Return the data if needed
  } catch (error) {
    throw error; // Re-throw the error for the calling component to handle
  }
};

export const updateBusinessType = async (
  id,
  businessTypeName,
  particular,
  createBy,
  updateBy
) => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.patch(
      `/businesstype/${id}`,
      {
        businessTypeName,
        particular,
        createBy,
        updateBy,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error; // You might want to handle errors in the calling component
  }
};
