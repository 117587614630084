import React from "react";
import FooterText from "../ChildComponents/FooterText";

function Footer() {
  return (
    <div>
      <FooterText />
    </div>
  );
}

export default Footer;
