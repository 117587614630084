import React from "react";

// componets Import
import HeroTitle from "../ChildComponents/HeroTitle";
import HeroForm from "../ChildComponents/HeroForm";

// import css
import "../CSS/hero.css";

function Hero() {
  return (
    <div className="hero">
      <div className="container pt-0 p-4">
        <div className="row">
          <div className="col-sm-12 col-md-6 mt-5 pt-5 ">
            <HeroTitle />
          </div>
          <div className="col-sm-12 col-md-6 py-5">
            <HeroForm />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
