import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL;

const api = axios.create({
  baseURL: `${baseURL}`,
});

export const emailCollector = async () => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${baseURL}/routeprotection`, {
      headers: {
        Authorization: token,
      },
    });
    const data = response.data.email;
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
export const userIdCollector = async () => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${baseURL}/routeprotection`, {
      headers: {
        Authorization: token,
      },
    });
    const data = response.data.userId;
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
