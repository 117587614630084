import React, { useState, useEffect } from "react";
import Nav from "../ChildComponents/Nav";
import Select from "react-select";
import "../CSS/button.css";
import "../CSS/lead.css";
import countries from "../Json/countries.json";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import Table from "react-bootstrap/Table";
import FormatListNumberedRtlIcon from "@mui/icons-material/FormatListNumberedRtl";
import Button from "react-bootstrap/Button";
import {
  clientData,
  createBusinessLead,
  updateLeadData,
  updateClientType,
} from "../Api/leadfatch";
import { businessTypeData } from "../Api/businessTypefatch";
import { dataSourceData } from "../Api/dataSourcefatch";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import EditIcon from "@mui/icons-material/Edit";
import { ToastContainer, toast } from "react-toastify";
import InputGroup from "react-bootstrap/InputGroup";
import FastForwardIcon from "@mui/icons-material/FastForward";

function ClientList() {
  const [loading, setLoading] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editableobj, setEditableobj] = useState();
  const [entryForm, setEntryForm] = useState(false);
  const [leadTable, setLeadTable] = useState(true);

  // selected State
  const [selectedBusinessType, setSelectedBusinessType] = useState("");
  const [selectedDataSource, setSelectedDataSource] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  // input State

  const [businessType, setBusinessType] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [contactPersonName, setContactPersonName] = useState("");
  const [contactPersonMobileNumber, setContactPersonMobileNumber] =
    useState("");
  const [contactPersonEmail, setContactPersonEmail] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessMobile, setBusinessMobile] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");
  const [remark, setRemark] = useState("");
  const [clientType, setClientType] = useState("client");
  const [openingBalance, setOpeningBalance] = useState(0);

  const [filteredData, setFilteredData] = useState([]);

  const leadfetchData = async () => {
    try {
      setLoading(true);
      const businessTypeFatcing = await businessTypeData();
      const dataSourceFatcing = await dataSourceData();
      setBusinessType(businessTypeFatcing);
      setDataSource(dataSourceFatcing);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      const clientDataList = await clientData();
      setDataTable(clientDataList);
      setFilteredData(clientDataList);
      setLoading(false);
    }
  };

  useEffect(() => {
    leadfetchData();
  }, []);

  const createHandelar = async (e) => {
    e.preventDefault();
    try {
      if (
        !selectedBusinessType ||
        !selectedDataSource ||
        !contactPersonMobileNumber ||
        !selectedCountry ||
        !selectedDistrict ||
        !businessMobile
      ) {
        toast.error(
          "Must be given information Business Type, Data Source, Person Mobile Number, Country and District"
        );
      } else {
        await createBusinessLead(
          selectedBusinessType.value,
          selectedDataSource.value,
          contactPersonName,
          contactPersonMobileNumber,
          contactPersonEmail,
          businessName,
          businessMobile,
          businessEmail,
          businessAddress,
          selectedCountry.value,
          selectedDistrict.value,
          remark,
          clientType,
          openingBalance
        );
        toast("Successfully Added");
        openTable();
        setSelectedBusinessType("");
        setSelectedDataSource("");
        setSelectedCountry("");
        setSelectedDistrict("");
        setContactPersonName("");
        setContactPersonMobileNumber("");
        setContactPersonEmail("");
        setBusinessName("");
        setBusinessMobile("");
        setBusinessEmail("");
        setBusinessAddress("");
        setRemark("");
        leadfetchData();
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    }
  };

  const editHandler = (_Id) => {
    leadfetchData();
    setEditMode(true);
    const toBeEditedLeadData = dataTable.find((item) => item._id === _Id);
    console.log(toBeEditedLeadData);
    if (toBeEditedLeadData) {
      setEditableobj(toBeEditedLeadData);
      openEntry();
      // Find the business type option object
      const selectedBusinessTypeOption = businessType.find(
        (option) => option._id === toBeEditedLeadData.businessType._id
      );
      const formattedSelectedBusinessType = {
        value: selectedBusinessTypeOption._id,
        label: selectedBusinessTypeOption.businessTypeName,
      };
      setSelectedBusinessType(formattedSelectedBusinessType);
      // Find the data Source option object
      const selectedDataSourceOption = dataSource.find(
        (option) => option._id === toBeEditedLeadData.dataSource._id
      );
      const formattedSelectedDataSource = {
        value: selectedDataSourceOption._id,
        label: selectedDataSourceOption.dataSourceName,
      };
      setSelectedDataSource(formattedSelectedDataSource);
      setContactPersonName(toBeEditedLeadData.contactPersonName);
      setContactPersonMobileNumber(
        toBeEditedLeadData.contactPersonMobileNumber
      );
      setContactPersonEmail(toBeEditedLeadData.contactPersonEmail);
      setBusinessName(toBeEditedLeadData.businessName);
      setBusinessMobile(toBeEditedLeadData.businessMobile);
      setBusinessEmail(toBeEditedLeadData.businessEmail);
      setBusinessAddress(toBeEditedLeadData.businessAddress);
      // Find the country option object
      const selectedCountry = countries.find(
        (option) => option.code2 === toBeEditedLeadData.businessCountry
      );
      console.log(selectedCountry);
      const formattedSelectedCountry = {
        value: selectedCountry.code2,
        label: selectedCountry.name,
      };
      setSelectedCountry(formattedSelectedCountry);
      // Find the Districts option object
      if (selectedCountry) {
        const selectedDistrict = selectedCountry.states.find(
          (state) => state.code === toBeEditedLeadData.businessDistrict
        );
        if (selectedDistrict) {
          const formattedSelectedDistrict = {
            value: selectedDistrict.code,
            label: selectedDistrict.name,
          };
          setSelectedDistrict(formattedSelectedDistrict);
        } else {
          console.error(
            `District with code ${toBeEditedLeadData.businessDistrict} not found.`
          );
        }
      } else {
        console.error(
          `Country with code ${toBeEditedLeadData.businessCountry} not found.`
        );
      }

      setRemark(toBeEditedLeadData.remark);
      setClientType(toBeEditedLeadData.clientType);
      setOpeningBalance(toBeEditedLeadData.openingBalance);
    } else {
      console.error(`Lead with ID ${_Id} not found.`);
    }
  };

  const updateHandaler = async (e) => {
    e.preventDefault();
    if (
      !selectedBusinessType ||
      !selectedDataSource ||
      !contactPersonMobileNumber ||
      !selectedCountry ||
      !selectedDistrict ||
      !businessMobile
    ) {
      toast.error(`Please provide Requered Input Filed`);
      return;
    }

    try {
      await updateLeadData(
        editableobj._id,
        selectedBusinessType.value,
        selectedDataSource.value,
        contactPersonName,
        contactPersonMobileNumber,
        contactPersonEmail,
        businessName,
        businessMobile,
        businessEmail,
        businessAddress,
        selectedCountry.value,
        selectedDistrict.value,
        remark,
        clientType,
        openingBalance
      );
      console.log();
      toast(`Successfully Updated`);
      setEditMode(false);
      leadfetchData();
      openTable(editableobj._id);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage);
      } else {
        toast.error("Something went wrong while updating.");
      }
    }
  };

  const openEntry = () => {
    setLeadTable(false);
    setEntryForm(true);
  };
  const openTable = () => {
    setEntryForm(false);
    setLeadTable(true);
  };
  const handleDataSourceChange = (selectedOption) => {
    setSelectedDataSource(selectedOption);
  };

  const handleBuseinssTypeChange = (selectedOption) => {
    setSelectedBusinessType(selectedOption);
  };

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };
  const handleDistrictChange = (selectedOption) => {
    setSelectedDistrict(selectedOption);
  };

  const filterdata = async (value) => {
    const filteredView = dataTable.filter((item) => {
      return (
        item.contactPersonName.toLowerCase().includes(value.toLowerCase()) ||
        item.contactPersonMobileNumber
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        item.contactPersonEmail.toLowerCase().includes(value.toLowerCase()) ||
        item.businessName.toLowerCase().includes(value.toLowerCase()) ||
        item.businessMobile.toLowerCase().includes(value.toLowerCase()) ||
        item.createBy.toLowerCase().includes(value.toLowerCase())
      );
    });

    setFilteredData(filteredView);
  };

  return (
    <div>
      {/* header */}
      <Nav />
      {/* icon with button */}

      <div className="container my-3">
        <div>
          <div className=" inner-nav-icon my-4 ">
            <Link
              onClick={openTable}
              className={`p-3 me-3 bg-light shadow rounded ${
                leadTable ? "border border-1 border-solid border-primary" : ""
              }`}
            >
              <FormatListNumberedRtlIcon className="text-center" />
            </Link>
            <Link
              onClick={openEntry}
              className={`p-3 me-3 bg-light shadow rounded ${
                entryForm ? "border border-1 border-solid border-primary" : ""
              }`}
            >
              <LibraryAddIcon className="text-center" />
            </Link>
          </div>
        </div>
      </div>
      {/* Page Title */}
      <div className="container mt-5">
        <p className="fw-bold h5 title-color">Client List</p>
      </div>
      {/* entry form in body */}
      {entryForm ? (
        <div className="container mt-4">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3 ">
              <div className="shadow p-2 rounded">
                <Form>
                  <Form.Label className="my-3 fw-bold border-bottom">
                    Complementary Information
                  </Form.Label>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-4">
                      <Form.Group
                        className="mb-3 mt-2"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Business Type</Form.Label>
                        <Select
                          value={selectedBusinessType}
                          onChange={handleBuseinssTypeChange}
                          className="custom-select-sm"
                          isClearable
                          options={businessType.map((option) => ({
                            value: option._id,
                            label: option.businessTypeName,
                          }))}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-4">
                      <Form.Group
                        className="mb-3 mt-2"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Data Collection Source</Form.Label>
                        <Select
                          value={selectedDataSource}
                          onChange={handleDataSourceChange}
                          className="custom-select-sm"
                          isClearable
                          options={dataSource.map((option) => ({
                            value: option._id,
                            label: option.dataSourceName,
                          }))}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-4">
                      <Form.Group
                        className="mb-3 mt-2"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Client Type</Form.Label>
                        <div className="">
                          {["checkbox"].map((type) => (
                            <div key={`inline-${type}`} className="mb-3">
                              <Form.Check
                                inline
                                label="Lead"
                                name="group1"
                                type={type}
                                id={`inline-${type}-1`}
                                defaultChecked
                                disabled
                              />
                              <Form.Check
                                inline
                                label="Client"
                                name="group1"
                                type={type}
                                id={`inline-${type}-2`}
                                disabled
                              />
                              <Form.Check
                                inline
                                label="Suplier"
                                type={type}
                                id={`inline-${type}-3`}
                                disabled
                              />
                            </div>
                          ))}
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4 mb-3">
              <div className="shadow p-2 rounded">
                <Form>
                  <Form.Label className="my-3 fw-bold border-bottom">
                    Contact Person Information
                  </Form.Label>
                  <Form.Group
                    className="mb-3 mt-2"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Contact Person Name</Form.Label>
                    <Form.Control
                      value={contactPersonName}
                      onChange={(e) => setContactPersonName(e.target.value)}
                      size="sm"
                      className="shadow form-input"
                      type="text"
                      placeholder="ex:- Mr. John"
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3 mt-2"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Contact Person Mobile</Form.Label>
                    <Form.Control
                      value={contactPersonMobileNumber}
                      onChange={(e) =>
                        setContactPersonMobileNumber(e.target.value)
                      }
                      size="sm"
                      className="shadow form-input"
                      type="text"
                      placeholder="ex:- 8801234567890"
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3 mt-2"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Contact Person Email</Form.Label>
                    <Form.Control
                      value={contactPersonEmail}
                      onChange={(e) => setContactPersonEmail(e.target.value)}
                      size="sm"
                      className="shadow form-input"
                      type="text"
                      placeholder="ex:- email@email.com"
                    />
                  </Form.Group>
                </Form>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-8 ">
              <div className="shadow p-2 rounded">
                <Form>
                  <Form.Label className="my-3 fw-bold border-bottom">
                    Business Information
                  </Form.Label>

                  <div className="row">
                    <div className="col-sm-12 col-md-6">
                      <Form.Group
                        className="mb-3 mt-2"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Busniess Name</Form.Label>
                        <Form.Control
                          value={businessName}
                          onChange={(e) => setBusinessName(e.target.value)}
                          size="sm"
                          className="shadow form-input"
                          type="text"
                          placeholder="ex:- Google , Yahoo , Microsoft"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3 mt-2"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Business Mobile</Form.Label>
                        <Form.Control
                          value={businessMobile}
                          onChange={(e) => setBusinessMobile(e.target.value)}
                          size="sm"
                          className="shadow form-input"
                          type="text"
                          placeholder="ex:- 8801234567890"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3 mt-2"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Business Email</Form.Label>
                        <Form.Control
                          value={businessEmail}
                          onChange={(e) => setBusinessEmail(e.target.value)}
                          size="sm"
                          className="shadow form-input"
                          type="text"
                          placeholder="ex:- admin@admin.com"
                        />
                      </Form.Group>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <Form.Group
                        className="mb-3 mt-2"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Business Address</Form.Label>
                        <Form.Control
                          value={businessAddress}
                          onChange={(e) => setBusinessAddress(e.target.value)}
                          size="sm"
                          className="shadow form-input"
                          type="text"
                          placeholder="ex:- Chittagong, Bangladesh"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3 mt-2"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Country</Form.Label>
                        <Select
                          value={selectedCountry}
                          onChange={handleCountryChange}
                          options={countries.map((country) => ({
                            value: country.code2,
                            label: country.name,
                            states: country.states.map((state) => ({
                              value: state.code,
                              label: state.name,
                            })),
                          }))}
                          isClearable
                          placeholder="Select Country"
                          className="custom-select-sm"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3 mt-2"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>District</Form.Label>
                        <Select
                          value={selectedDistrict}
                          onChange={handleDistrictChange}
                          options={
                            selectedCountry && selectedCountry.states
                              ? selectedCountry.states.map((state) => ({
                                  value: state.value,
                                  label: state.label,
                                }))
                              : []
                          }
                          isClearable
                          placeholder="Select District"
                          className="custom-select-sm"
                          isDisabled={!selectedCountry}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3 ">
              <div className="shadow p-2 rounded">
                <Form>
                  <Form.Label className="my-3 fw-bold border-bottom">
                    Others Information
                  </Form.Label>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-6">
                      <Form.Group
                        className="mb-3 mt-2"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Opening Balance</Form.Label>
                        <Form.Control
                          value={openingBalance}
                          onChange={(e) => setOpeningBalance(e.target.value)}
                          size="sm"
                          className="form-input"
                          type="text"
                          placeholder="ex:- 10000.00"
                          disabled
                        />
                      </Form.Group>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                      <Form.Group
                        className="mb-3 mt-2"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Remark</Form.Label>
                        <Form.Control
                          value={remark}
                          onChange={(e) => setRemark(e.target.value)}
                          as="textarea"
                          aria-label="With textarea"
                          size="sm"
                          className="shadow form-input"
                          placeholder="Remark"
                        />
                      </Form.Group>
                    </div>
                    <div className="text-end me-3 mt-4 mb-2">
                      <Button
                        className="btn btn-brand text-end"
                        variant="primary"
                        type="submit"
                        size="sm"
                        onClick={editMode ? updateHandaler : createHandelar}
                      >
                        {editMode ? "Save Change" : "Save Data"}
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {/*Table in body */}
      {leadTable ? (
        <div className="mt-4 container">
          <div className="shadow p-2 rounded">
            <InputGroup size="sm" className="my-3 shadow-sm  rounded w-50 ">
              <InputGroup.Text id="basic-addon1">Search</InputGroup.Text>
              <Form.Control
                placeholder="Search by Person Name, Person Mobile, Business name"
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                size="sm"
                onChange={(e) => filterdata(e.target.value)}
              />
            </InputGroup>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>SL#</th>
                  <th>Person Name</th>
                  <th>Person Mobile</th>
                  <th>Business Name</th>
                  <th>Business Mobile</th>
                  <th>Business Type</th>
                  <th>Data Source</th>
                  <th>Create By</th>
                  <th>Update By</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr className="spinner-grow text-info mt-4 " role="status">
                    <td className="visually-hidden">Loading...</td>
                  </tr>
                ) : dataTable && dataTable.length > 0 ? (
                  filteredData
                    .slice()
                    .reverse()
                    .map((item, index, array) => (
                      <tr key={item.id}>
                        <td>{array.length - index}</td>
                        <td>{item.contactPersonName}</td>
                        <td>{item.contactPersonMobileNumber}</td>
                        <td>{item.businessName}</td>
                        <td>{item.businessMobile}</td>
                        <td>{item.businessType.businessTypeName}</td>
                        <td>{item.dataSource.dataSourceName}</td>
                        <td>
                          <Link
                            to={`/profile/${item.createBy}`}
                            className="text-decoration-none text-dark"
                          >
                            User ID - # {item.createBy}
                          </Link>
                        </td>
                        <td>
                          {item.updateBy ? (
                            <Link
                              to={`/profile/${item.updateBy}`}
                              className="text-decoration-none text-dark"
                            >
                              User ID - # {item.updateBy}
                            </Link>
                          ) : (
                            "Not Updated !!"
                          )}
                        </td>
                        <td className="text-center">
                          <EditIcon
                            onClick={() => editHandler(item._id)}
                            className="pointer text-light bg-dark p-1 rounded me-2"
                          />
                          <Link
                            to={`/client/${item._id}`}
                            className="text-decoration-none text-dark"
                          >
                            <FastForwardIcon className="pointer text-light bg-dark p-1 rounded" />
                          </Link>
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan="10" className="text-center py-5">
                      No Data Available
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      ) : (
        ""
      )}
      <ToastContainer />
    </div>
  );
}

export default ClientList;
