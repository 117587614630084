import Nav from "../ChildComponents/Nav";
import "../CSS/businessInfoview.css";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { singleClient } from "../Api/leadfatch";
import Countrys from "../Json/countries.json";
import Select from "react-select";
import { leadData, clientData } from "../Api/leadfatch";
import { useNavigate } from "react-router-dom";
import FastRewindIcon from "@mui/icons-material/FastRewind";

function ClientInfo() {
  const [data, setData] = useState(null);
  const [country, setCountry] = useState(null);
  const [district, setDistrict] = useState(null);
  const [lead, setLead] = useState([]);
  const [client, setClient] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [clientOptions, setClientOptions] = useState(null);

  const navigate = useNavigate();

  const { id } = useParams();

  const fetchData = async () => {
    try {
      const getData = await singleClient(id);
      const getLead = await leadData();
      const getClient = await clientData();
      setLead(getLead);
      setClient(getClient);
      setData(getData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  useEffect(() => {
    if (data) {
      if (data.businessCountry) {
        const matchingCountry = Countrys.find(
          (country) => country.code2 === data.businessCountry
        );
        if (matchingCountry) {
          setCountry(matchingCountry.name);
        }
      }
      if (data.businessDistrict) {
        for (const country of Countrys) {
          const matchingDistrict = country.states.find(
            (state) => state.code === data.businessDistrict
          );
          if (matchingDistrict) {
            setDistrict(matchingDistrict.name);
            break;
          }
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (lead.length > 0 && client.length > 0) {
      const combinator = [...lead, ...client];
      const clientOption = combinator.map((item) => ({
        value: item._id,
        label: `${item.contactPersonName} -/- ${item.businessName} -/- ${item.contactPersonMobileNumber} -/- ${item.businessMobile}`,
      }));
      setClientOptions(clientOption);
      const selectedClientFromId = combinator.find((item) => item._id === id);
      setSelectedClient({
        value: selectedClientFromId._id,
        label: `${selectedClientFromId.contactPersonName} -/- ${selectedClientFromId.businessName} -/- ${selectedClientFromId.contactPersonMobileNumber} -/- ${selectedClientFromId.businessMobile}`,
      });
    }
  }, [lead, client, id]);

  const clientinfoChangeHandalar = (selectedOption) => {
    if (selectedOption) {
      setData(null);
      navigate(`/client/${selectedOption.value}`);
    }
  };

  if (!data) {
    return (
      <div className="text-center mt-5 pt-5">
        <div className="spinner-grow text-info mt-4 text-center " role="status">
          <div className="visually-hidden">Loading...</div>
        </div>
      </div>
    );
  }

  const backPage = () => {
    setData(null);
    window.history.back();
  };

  return (
    <div>
      <Nav />
      {/* main body */}
      <div className="container">
        {/* page Title */}
        <div className="container mt-3 mb-4">
          <div className="row">
            <div className="col-sm-6">
              <p className="fw-bold h5 title-color">Client Information</p>
            </div>
            <div className="col-sm-6 d-flex justify-content-end">
              <Select
                value={selectedClient}
                onChange={clientinfoChangeHandalar}
                className="custom-select-sm"
                options={clientOptions}
              />
              <FastRewindIcon
                onClick={backPage}
                className="pointer text-light bg-danger ms-3 fs-2 mt-1 px-1  shadow  rounded"
              />
            </div>
          </div>
        </div>
        {/* Information */}
        <div>
          <div className="shadow p-3 rounded">
            <p className="my-3  body-title border-bottom mb-3">
              Query and Contact Information
            </p>
            <div className="row">
              <div className="col-6 mb-3">
                <p className="point">
                  Business Type :-{" "}
                  <span>{data.businessType.businessTypeName}</span>
                </p>
                <p className="point">
                  Data Collection Source :-{" "}
                  <span>{data.dataSource.dataSourceName}</span>
                </p>
                <p className="point">
                  Client Type :- <span>{data.clientType}</span>
                </p>
              </div>
              <div className="col-6  mb-3">
                <p className="point">
                  Contact Person Name :- <span>{data.contactPersonName}</span>
                </p>
                <p className="point">
                  Contact Person Mobile :-{" "}
                  <span>{data.contactPersonMobileNumber}</span>
                </p>
                <p className="point">
                  Contact Person Email :- <span>{data.contactPersonEmail}</span>
                </p>
              </div>
              <p className=" body-title border-bottom mb-3">
                Business Information
              </p>
              <div className="col-6  mb-3">
                <p className="point">
                  Business Name :- <span>{data.businessName}</span>
                </p>
                <p className="point">
                  Business Mobile :- <span>{data.businessMobile}</span>
                </p>
                <p className="point">
                  Business Email :- <span>{data.businessEmail}</span>
                </p>
              </div>

              <div className="col-6">
                <p className="point">
                  Business Address :- <span>{data.businessAddress}</span>
                </p>
                <p className="point">
                  Country :- <span>{country}</span>
                </p>
                <p className="point">
                  District :- <span>{district}</span>
                </p>
                <p className="point">
                  Remark :- <span>{data.remark}</span>
                </p>
              </div>
            </div>
          </div>
          <div className="my-4 client-info-view-btn">
            <a className="btn btn-brand  shadow me-3 mb-3" href="#">
              Asain Project
            </a>
            <a className="btn btn-brand  shadow me-3  mb-3" href="#">
              Create Invoice
            </a>
            <a className="btn btn-brand  shadow me-3  mb-3" href="#">
              Invoice List
            </a>
            <a className="btn btn-brand  shadow me-3  mb-3" href="#">
              Client Ledger
            </a>
            <a className="btn btn-brand  shadow me-3  mb-3" href="#">
              Visiting History
            </a>
            <a className="btn btn-brand  shadow me-3  mb-3" href="#">
              Comments
            </a>
            <a className="btn btn-brand  shadow me-3  mb-3" href="#">
              Service List
            </a>
            <a className="btn btn-brand  shadow me-3 mb-3" href="#">
              Secure Information
            </a>
            <a className="btn btn-brand  shadow me-3 mb-3" href="#">
              Transfer Lead to Client
            </a>
            <a className="btn btn-brand  shadow me-3 mb-3" href="#">
              Send Email
            </a>
            <a className="btn btn-brand  shadow me-3 mb-3" href="#">
              Send SMS
            </a>
            <a className="btn btn-brand  shadow me-3 mb-3" href="#">
              Support Token
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientInfo;
