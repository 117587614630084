import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import LandingHome from "./Page/LandingHome";
import Deshboard from "./Page/Deshboard";
import RouteProtector from "./CondisonalComponents/RouteProtector";
import LoginRouteProtection from "./CondisonalComponents/LoginRouteProtection";
import WifiOffOutlinedIcon from "@mui/icons-material/WifiOffOutlined";
import UserManagement from "./Page/UserManagement";
// import AccountsDeshboard from "./Page/AccountsDeshboard";
import MarketingLead from "./Page/MarketingLead";
import BusinessType from "./Page/BusinessType";
import DataSource from "./Page/DataSource";
import Footer from "./Components/Footer";
import Profile from "./Page/Profile";
import ProfileWthId from "./Page/ProfileWthId";
import ClientInfo from "./Page/ClientInfo";
import ClientList from "./Page/ClientList";

function App() {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  if (isOnline) {
    return (
      <>
        <Routes>
          <Route
            path="/"
            element={<LoginRouteProtection Components={LandingHome} />}
          />
          <Route
            path="/admin"
            element={<RouteProtector Components={Deshboard} />}
          />
          <Route
            path="/users"
            element={<RouteProtector Components={UserManagement} />}
          />
          <Route
            path="/marketing-lead"
            element={<RouteProtector Components={MarketingLead} />}
          />
          <Route
            path="/business-type"
            element={<RouteProtector Components={BusinessType} />}
          />
          <Route
            path="/data-source"
            element={<RouteProtector Components={DataSource} />}
          />
          <Route
            path="/client/:id"
            element={<RouteProtector Components={ClientInfo} />}
          />
          <Route
            path="/client-list"
            element={<RouteProtector Components={ClientList} />}
          />
          <Route
            path="/profile"
            element={<RouteProtector Components={Profile} />}
          />
          <Route
            path="/profile/:id"
            element={<RouteProtector Components={ProfileWthId} />}
          />
          <Route path="*" element={<Navigate to="/admin" />} />
        </Routes>
        <Footer />
      </>
    );
  } else {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "50px",
        }}
      >
        <div className="text-center">
          <WifiOffOutlinedIcon className="text-danger fs-1 my-4" />
          <p className="fw-bold h6">You are offline</p>
          <p>Please check your internet connection and try again.</p>
        </div>
        <Footer />
      </div>
    );
  }
}

export default App;
