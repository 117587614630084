import React, { useState, useEffect } from "react";
import EastIcon from "@mui/icons-material/East";
import moduleArray from "../Json/Module.json";
import AppIcon from "../image/appicon.jpg";
import "../CSS/nav.css";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import PofileImg from "../image/appicon.jpg";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import Button from "react-bootstrap/Button";
import ListIcon from "@mui/icons-material/List";
import { Link, useNavigate } from "react-router-dom";
import { userIdCollector } from "../Api/emailCollector";

function Nav() {
  const [selectedModule, setSelectedModule] = useState("");
  const [show, setShow] = useState(false);
  const [userId, setUserID] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getData = async () => {
    const finaluserId = await userIdCollector();
    setUserID(finaluserId);
  };
  useEffect(() => {
    getData();
  }, []);
  const toggleStyle = {
    backgroundColor: "#f8fbe8",
    border: "none",
    boxShadow: "none",
    padding: "0",
    cursor: "pointer",
  };
  const iconStyle = {
    display: "none",
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    navigate("/");
  };

  return (
    <div className="bg-brand shadow-sm sticky-top">
      <div className="container-fluid mobile">
        <div className="row">
          <div className="col col-md-8 col-xl-8 d-flex">
            {/* Off Nav */}
            <Button
              className="off-nav me-2"
              variant="light"
              onClick={handleShow}
            >
              <ListIcon />
            </Button>
            {/* Logo */}
            <img
              className="img-fluid mt-2 pt-1 pointer me-4 rounded"
              style={{ width: "35px", height: "35px" }}
              src={AppIcon}
              alt=""
            />
            {/* nav */}
            <nav className="navbar navbar-expand-lg main-nav">
              <ul className="navbar-nav">
                <Link to="/admin" className="nav-link nav-title">
                  Deshborad
                </Link>
                {moduleArray.map((item) => (
                  <li className="nav-item" key={item.title}>
                    {!selectedModule ? (
                      <Link
                        onClick={() => setSelectedModule(item.title)}
                        className="text-decoration-none nav-link nav-title me-3"
                      >
                        {item.title}
                      </Link>
                    ) : (
                      selectedModule === item.title && (
                        <ul className="sub-modules navbar-nav">
                          {item.subModule.map((subItem) => (
                            <li className="nav-item" key={subItem.title}>
                              <Link
                                to={subItem.url}
                                className="nav-link nav-title"
                              >
                                {subItem.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )
                    )}
                  </li>
                ))}
              </ul>
            </nav>

            {/* nav End Button */}
          </div>

          <div className=" col col-md-4 col-xl-4 ">
            <div className="d-flex justify-content-end">
              <div className="me-3 mt-2 main-nav">
                <div className="mt-1">
                  <Form.Select
                    size="sm"
                    aria-label="Default select example"
                    value={selectedModule}
                    onChange={(e) => setSelectedModule(e.target.value)}
                  >
                    <option value="">Module</option>
                    {moduleArray.map((item) => (
                      <option key={item.title} value={item.title}>
                        {item.title}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </div>

              <Dropdown className="user-icon">
                <Dropdown.Toggle style={toggleStyle} id="dropdown-basic">
                  <img
                    className="rounded-circle me-3 shadow-sm pointer"
                    style={{
                      width: "30px",
                      height: "30px",
                      marginTop: "15px",
                    }}
                    src={PofileImg}
                    alt=""
                  />
                  <span style={iconStyle}>&#9660;</span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Link to="/profile" className="text-decoration-none">
                    <Dropdown.Item href="#/action-1">Profile</Dropdown.Item>
                  </Link>
                  <Link to="/users" className="text-decoration-none">
                    <Dropdown.Item href="#/action-2">
                      Users Managment
                    </Dropdown.Item>
                  </Link>
                  <Dropdown.Divider />
                  <Dropdown.Item className="fw-bold text-danger" disabled>
                    Login User ID #{userId}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <p className="nav-right mt-3" onClick={handleLogout}>
                <PowerSettingsNewIcon className="me-1" />
                Logout
              </p>
            </div>
          </div>
        </div>
      </div>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Offcanvas</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div>
            <Form.Select
              size="sm"
              aria-label="Default select example"
              value={selectedModule}
              onChange={(e) => setSelectedModule(e.target.value)}
            >
              <option value="">Module</option>
              {moduleArray.map((item) => (
                <option key={item.title} value={item.title}>
                  {item.title}
                </option>
              ))}
            </Form.Select>
          </div>
          <div>
            <nav className="navbar ">
              <ul className="navbar-nav">
                {moduleArray.map((item) => (
                  <li className="nav-item ms-2 mt-2" key={item.title}>
                    {!selectedModule ? (
                      <a
                        className={`nav-link nav-title me-3 `}
                        href="/"
                        onClick={() => setSelectedModule(item.title)}
                      >
                        {item.title}
                      </a>
                    ) : (
                      selectedModule === item.title && (
                        <ul className="sub-modules navbar-nav">
                          {item.subModule.map((subItem) => (
                            <li className="nav-item" key={subItem.title}>
                              <a
                                className="nav-link nav-title"
                                href={subItem.url}
                              >
                                {subItem.title}
                              </a>
                            </li>
                          ))}
                        </ul>
                      )
                    )}
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default Nav;
