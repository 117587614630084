import Nav from "../ChildComponents/Nav";
import "../CSS/profile.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userIdCollector } from "../Api/emailCollector";
import waitnsee from "../image/waitnsee.png";

function Profile() {
  const navigate = useNavigate();

  const getData = async () => {
    const finaluserId = await userIdCollector();
    navigate(`/profile/${finaluserId}`);
  };

  useEffect(() => {
    getData();
  });

  return (
    <div>
      <Nav />
      <div className="text-center mt-5">
        <img className="img-fluid" src={waitnsee} alt="" />
      </div>
    </div>
  );
}

export default Profile;
