import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";

import axios from "axios";

function RouteProtector(props) {
  const { Components } = props;
  const { id } = useParams();

  const baseURL = process.env.REACT_APP_BASE_URL;

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    checkTokenValidity(token);
  }, [id]);

  const checkTokenValidity = async (token) => {
    try {
      await axios.get(`${baseURL}/routeprotection`, {
        headers: {
          Authorization: token,
        },
      });
      navigate(`/admin`);
    } catch (error) {
      navigate("/");
    }
  };
  return (
    <div>
      <Components />
    </div>
  );
}

export default RouteProtector;
